app-user-profile-info {
    dd {
        i {
            @extend .text-grey;
            @extend .me-1;
        }

    }

    .background {
        margin-top: 15px;
        height: 200px;
        background-image: url("/assets/images/bg-avatar.png");
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 10px 10px 10px 10px;
    }

    .employee-from-another-company {
        @extend .text-white;
        @extend .bg-secondary;
        @extend .small;
        position: absolute;
        padding-bottom: $spacer/2;
        padding-top: $spacer/2;
        margin-bottom: 0;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        bottom: 0;
        right: 0;
        width: 100%;
        text-align: center;
        opacity: 0.6;
    }

    app-user-photo {
        &.avatar {
            border: 5px #fff solid;
            border-radius: 100vh;
            width: 100%;
            overflow: hidden;
            max-width: 14rem;
            margin-top: -7rem;
        }

        @include media-breakpoint-up(md) {
            &.avatar {
                max-width: 12rem;
                margin-top: -12.2rem;
            }
        }

        &.birthday {
            box-shadow: 0 0 15px 10px rgba(225, 6, 0, 0.6);
            animation: glow 1.5s linear infinite alternate;
        }
    }

    .user-data {

        .btn-link {
            padding: 0;
        }
    }

    .btn {
        &.teams {
            border-radius: 10px;
        }

        &.download {
            margin-left: 10px;
            border-radius: 10px;
        }


        &.thank {
            padding: rfs-fluid-value(rfs-value(5px)) rfs-fluid-value(rfs-value(10px));
            border-radius: 10px;

            i {
                margin-right: 5px;
            }
        }
    }
}
