app-user-profile {
    > .wrapper {
        height: 100%;
        width: 100%;
        background-repeat: no-repeat;
        background-attachment: scroll;
        background-position: 100% 100%;
        background-size: 700px 550px;
        overflow: hidden;
        @media (max-width: 768px) {
            overflow: scroll;
        }

        &.male,
        &.female {
            background-image: none
        }

        @include media-breakpoint-up(md) {
            &.male {
                background-image: url("/assets/images/profile-male.svg");
            }

            &.female {
                background-image: url("/assets/images/profile-female.svg");
            }
        }

        .container {
            display: grid;
            grid-template-columns: 30% 70%;
            @media (max-width: 768px) {
                height: unset;
                grid-template-columns: fit-content(100%);
            }
            height: 100%;
            width: 100% !important;
            max-width: 100% !important;
            gap: 1rem;
            /* Между колонками */
            padding: 1rem 0;
            /* Паддинг внутри грида */
            box-sizing: border-box;
            /* Учет паддинга в общей ширине и высоте */
        }

        .card {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            @media (max-width: 768px) {
                height: unset;
                overflow: unset;
            }
            overflow: hidden;

            .card-body {
                width: 100%;
                height: 100%;
                //на маленьком разрешении содержимое не помещается по Y. Тут на свое усмотрение сделал добавив в этот блок.
                overflow: auto;



            }
        }

        .scrollable {
            overflow-y: auto;
            height: 100%;
        }
    }

}

@import 'shared/styles/scrollbar';
